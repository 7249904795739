import gql from 'graphql-tag';

export default gql`
  mutation updateQueueVersion($input: RelayUpdateQueueVerisonQueueInput!) {
    updateQueueVersion(input: $input) {
      queueIds
      version
      instanceType
    }
  }
`;
